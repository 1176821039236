<template>
    <div class="container overflow-hidden">
        <!-- Nav com breadcrumb -->
        <div class="nav-rotas-anteriores pt-5 px-3">
            <nav style="--bs-breadcrumb-divider: '>';" aria-label="breadcrumb">
                <ol class="breadcrumb">
                    <li class="breadcrumb-item"><router-link to="/">Home</router-link></li>
                    <li class="breadcrumb-item active" aria-current="page">Categorias</li>
                </ol>
            </nav>
        </div>

        <h1>Categorias:</h1>

        <div class="row">
            <menuCategorias :btn_ver_mais="btn_ver_mais"></menuCategorias>
        </div>

    </div>
</template>

<script>
import menuCategorias from '@/components/menuCategorias.vue'

export default {
  name: 'categorias',
  components: {
      menuCategorias,
  },
  // Quando crianos Data em um componente ele deve ser em formato de função 
  data() {
      return {
        tags: '',
        btn_ver_mais: false,
      }
  }
}


</script>

<style scoped>
    a {
        color: #000;
        font-weight: 600;
        text-decoration: none;
    }
    .breadcrumb{
    background-color: transparent;
}
</style>

